<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      :headers="headers"
      :show-operations="false"
      :resource-limit="1000"
      resource="auditLogs"
      :resource-query="{}"
      :meta-options="{}"
      :locale="locale"
      expanding-row
      show-filter
      :search-filters="filtersList"
      route-base="/utilities/audits/logs"
      do-route-filters
      :buttons="computedButtons"
      :search-filter-config="{ allowTextInput: false }"
      :export-options="{
        waitingContent: $t('common.interactions.download.waiting')
      }"
      @loading-error="handleLoadingError"
    >
      <template #expanding-row="{ row }">
        <expanding-row :row="row" />
      </template>
    </th-datatable>
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import ExpandingRow from './components/expanding-row'
import { useExportsStore } from '@/store/exports'

export default {
  components: {
    ExpandingRow
  },
  data() {
    const logsTypes = [
      {
        value: 'product',
        label: this.$t('common.audit_logs.types.product')
      }
    ]

    return {
      filtersList: [
        {
          name: 'created_at',
          type: 'daterange',
          label: this.$t('pages.audit_logs.all.filters.created_at.label'),
          formatValue: (value) => this.$date.formatDateRange(value),
          modifyFilter: (filterObject) => ({
            start: filterObject.start,
            end: filterObject.end
          })
        }
      ],
      headers: [
        {
          field: 'created_at',
          label: this.$t('pages.audit_logs.all.table.created_at'),
          truncate: true,
          formatter: (row, column) => {
            const date = safeGet(row, 'created_at.iso')
            if (!date) return '--'
            return this.$date.formatDateWithTimezone(date)
          }
        },
        {
          field: 'resource',
          label: this.$t('pages.audit_logs.all.table.resource'),
          truncate: true,
          formatter: (row, column) => {
            if (row.type) {
              const resource = row.type.split('.')[0]
              const logType = logsTypes.find((type) => type.value === resource)
              return logType && logType.label ? logType.label : row.type
            } else {
              return '--'
            }
          }
        },
        {
          field: 'type',
          label: this.$t('common.headers.type.title'),
          truncate: true,
          formatter: (row, column) => {
            if (row.type) {
              return row.type.split('.')[1] || '--'
            } else {
              return '--'
            }
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      locale: 'Config/getLocale',
      timeZone: 'Config/getTimeZone'
    }),
    buttons() {
      return [
        {
          type: 'custom_export',
          scopes: ['utilities:audits:logs:export'],
          label: this.$t('common.interactions.buttons.export'),
          clickHandler: ({ handleDownload, resourceOptions }) => {
            this.handleExport({ handleDownload, resourceOptions })
          }
        }
      ]
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  methods: {
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.audit_logs.title')
        })
      })
    },
    async handleExport({ resourceOptions }) {
      const query = {
        ...resourceOptions,
        query: {
          ...resourceOptions.query,
          format: 'csv'
        }
      }

      try {
        const { data } = await th.auditLogsV1().getAll(query)

        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.audit_logs.title',
            date: new Date(),
            action: {
              entity: 'auditLogsV1',
              handler: 'getAll',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.audit_logs.title')
          })
        })
      }
    }
  }
}
</script>

<style scoped></style>
