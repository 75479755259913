<template>
  <div>
    <vue-json-pretty v-if="row.change" :path="'res'" :data="row.change" />
    <div v-else>
      {{ $t('pages.audit_logs.no_data') }}
    </div>
  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
  components: {
    VueJsonPretty
  },
  props: {
    row: {
      type: Object,
      required: false,
      default: undefined
    }
  }
}
</script>

<style scoped>
.vjs__tree :deep(.vjs__value__string) {
  color: #279ff6;
}
</style>
