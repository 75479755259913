<template>
  <th-page-wrapper>
    <th-datatable
      ref="thDataTable"
      resource="auditActions"
      show-filter
      :do-route="false"
      :headers="headers"
      :locale="locale"
      :resource-limit="1000"
      :resource-query="resourceQuery"
      :search-filters="filtersList"
      :show-operations="false"
      :transform-fetched-data="transformFetchedData"
      @loading-error="handleLoadingError"
      @search-filter-submit="submitFilter"
    />
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import th from '@tillhub/javascript-sdk'
import qs from 'qs'
import omit from 'just-omit'
import safeGet from 'just-safe-get'
import cloneDeep from 'clone-deep'
import mapValues from 'just-map-values'

export default {
  metaInfo() {
    return {
      title: this.$t('nav.main.items.utilities.audits')
    }
  },
  data() {
    return {
      actionTypes: [],
      temp: false,
      filterOptions: {},
      searchFilterValues: {},
      searchFilters: {},
      width: 500
    }
  },
  computed: {
    ...mapGetters({
      locale: 'Config/getLocale',
      currentLocation: 'Config/getCurrentLocation'
    }),
    filtersList() {
      return [
        {
          name: 'branch',
          type: 'remote-search-select',
          label: this.$t('pages.reports.statistics.products.branch'),
          placeholder: this.$t('common.inputs.placeholders.select'),
          resource: 'branches',
          overrideInitialFetchHandler: 'get',
          fetchHandler: 'getAll',
          computeName: this.$formatBranch,
          modifyQuery: (q) => ({
            q,
            fields: ['name', 'branch_number'],
            deleted: false
          })
        },
        {
          name: 'register',
          type: 'remote-search-select',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('pages.reports.statistics.products.register'),
          resource: 'registers',
          overrideInitialFetchHandler: 'get',
          fetchHandler: 'getAll',
          computeName: this.$formatRegister,
          modifyQuery: (q) => ({ q, limit: 50, deleted: false })
        },
        {
          name: 'staff',
          type: 'remote-search-select',
          label: this.$t('pages.transactions.all.table.staff'),
          resource: 'staff',
          overrideInitialFetchHandler: 'get',
          fetchHandler: 'getAll',
          computeName: (staff) =>
            this.$formatStaff(staff, ['staff_number', 'fullName'], ' - '),
          modifyQuery: (q) => ({ q, limit: 50, deleted: false })
        },
        {
          name: 'type',
          type: 'select',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('pages.audits.all.filters.type.label'),
          options: this.actionTypes,
          filterable: true
        },
        {
          name: 'daterange',
          prop: ['start', 'end'],
          type: 'daterange',
          label: this.$t('pages.transactions.all.filters.date.label'),
          formatValue: (value) => this.$date.formatDateRange(value),
          modifyFilter: (filterObject) => ({
            start: filterObject.start,
            end: filterObject.end
          })
        }
      ]
    },
    headers() {
      return [
        {
          field: 'branch',
          label: this.$t('pages.audits.all.table.branch'),
          fallback: '-',
          minWidth: 150,
          truncate: true,
          formatter: (row) => {
            return (
              safeGet(row, 'branch.name') ||
              safeGet(row, 'branch.branch_number') ||
              '--'
            )
          }
        },
        {
          field: 'register',
          label: this.$t('pages.audits.all.table.register'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          formatter: (row) => {
            return (
              safeGet(row, 'register.name') ||
              safeGet(row, 'register.register_number') ||
              '--'
            )
          }
        },
        {
          field: 'date',
          label: this.$t('pages.audits.all.table.date'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          formatter: (row) => {
            if (!row.date) return '--'
            return this.$date.formatDateWithTimezone(row.date)
          }
        },
        {
          field: 'time',
          label: this.$t('pages.audits.all.table.time'),
          fallback: '-',
          minWidth: 80,
          truncate: true,
          formatter: (row) => {
            if (!row.date) return '--'
            return this.$date.formatTime(row.date)
          }
        },
        {
          field: 'type',
          label: this.$t('common.headers.type.title'),
          fallback: '-',
          minWidth: 150,
          truncate: true,
          formatter: (row) => {
            return row.type || '--'
          }
        },
        {
          field: 'data',
          label: this.$t('pages.audits.all.table.article_number'),
          fallback: '-',
          minWidth: 150,
          truncate: true,
          formatter: (row) => {
            return (
              safeGet(row, 'data.metadata.cart.item.product_number') || '--'
            )
          }
        },
        {
          field: 'data',
          label: this.$t('pages.audits.all.table.article_name'),
          fallback: '-',
          minWidth: 250,
          truncate: true,
          formatter: (row) => {
            return safeGet(row, 'data.metadata.cart.item.product_name') || '--'
          }
        },
        {
          field: 'data',
          label: this.$t('pages.audits.all.table.quantity'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          formatter: (row) => {
            return safeGet(row, 'data.metadata.cart.item.quantity') || '--'
          }
        },
        {
          field: 'data',
          label: this.$t('pages.audits.all.table.article_price'),
          fallback: '-',
          minWidth: 100,
          truncate: true,
          formatter: (row) => {
            const cents = safeGet(row, 'data.metadata.cart.item.article_price')
            return cents ? (cents / 100).toFixed(2) : '--'
          }
        },
        {
          field: 'staff',
          label: this.$t('pages.audits.all.table.staff'),
          fallback: '-',
          minWidth: 150,
          truncate: true,
          formatter: (row) => {
            const staff = safeGet(row, 'staff')
            return this.getStaffName(staff)
          }
        }
      ]
    },
    parsedFilter() {
      if (this.$route.query) {
        const { filter } = qs.parse(this.$route.query) || {}
        return filter
      }

      return null
    },
    resourceQuery() {
      let query = {
        embed: ['user.register', 'user.staff', 'user.branch']
      }

      if (this.parsedFilter) {
        query = {
          ...query,
          ...omit(this.parsedFilter, 'daterange')
        }

        if (this.parsedFilter.daterange) {
          query.start = this.parsedFilter.daterange.start
          query.end = this.parsedFilter.daterange.end
        }
      }

      return query
    },
    hasFilters() {
      return !!this.parsedFilter
    }
  },
  async mounted() {
    this.actionTypes = await this.getActionTypes()
  },
  methods: {
    async getActionTypes() {
      let response
      try {
        response = await th.auditActions().getTypes()
      } catch (error) {
        return this.handleLoadingError(error)
      }

      const types = response.data && response.data[0] && response.data[0].types

      return (
        types &&
        types.map((type) => ({
          value: type,
          label: type
        }))
      )
    },
    submitFilter(filters) {
      const filter = qs.stringify({
        filter: mapValues(filters, (value, key) => {
          if (value === null || value === undefined || value.value === null)
            return undefined
          return value.value
        })
      })

      this.$router.push(`/utilities/audits?${filter}`)
    },
    transformFetchedData(data) {
      const rows = []

      data.forEach((auditAction) => {
        const multipleItems = safeGet(auditAction, 'data.metadata.cart.items')

        // cart items in the audit actions can be an array or an object
        // in case of an array need to create a new table row for each of them
        if (multipleItems && Array.isArray(multipleItems)) {
          multipleItems.forEach((cartItem) => {
            const newAuditAction = cloneDeep(auditAction)
            newAuditAction.data.metadata.cart.item = cartItem
            rows.push(newAuditAction)
          })
        } else {
          rows.push(auditAction)
        }
      })

      // Note: the number of rows will be overridden by the datatable with the length of local data,
      // hence, any number > 1000 will be incorrect

      return rows
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.audits.title')
        })
      })
    },
    getStaffName(item) {
      return this.$formatStaff(item) || '--'
    }
  }
}
</script>
